import { Fonts } from '../../../types';

export {
  BASE_FONT_SIZE,
  BASE_FONT_RELATIVE,
  bodyTypography,
  fontSizes,
  letterSpacings,
  lineHeights,
} from '../../eneco/default/tokens/typography';

/**
 * Primary tokens
 */
export const fonts: Fonts = [
  ['Boing', 700, 'boingBold.woff2', 'woff2'],
  ['Graphik', 400, 'graphikRegular.woff2', 'woff2'],
  ['Graphik', 700, 'graphikBold.woff2', 'woff2'],
];

export const fontWeights = {
  bodyRegular: '400',
  bodyBold: '700',
  headingRegular: '700',
};

export const headingTypography = {
  heading3xl: {
    fontSize: '{{ fontSizes[3XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[20] }}',
  },
  heading2xl: {
    fontSize: '{{ fontSizes[2XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[16] }}',
  },
  headingXl: {
    fontSize: '{{ fontSizes.XL }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[12] }}',
  },
  headingL: {
    fontSize: '{{ fontSizes.L }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[10] }}',
  },
  headingM: {
    fontSize: '{{ fontSizes.M }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[9] }}',
  },
  headingS: {
    fontSize: '{{ fontSizes.S }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  headingXs: {
    fontSize: '{{ fontSizes.XS }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading2xs: {
    fontSize: '{{ fontSizes[2XS] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading3xs: {
    fontSize: '{{ fontSizes[3XS] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[5] }}',
  },
};
