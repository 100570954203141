export const oxxioHeaderColors = {
  navBackgroundPrimary: '{{ neutralColors.neutralWhite }}',
  navBackgroundSecondary: '{{ brandColors.brandDarkBlue }}',
  navBackgroundTertiary: '{{ neutralColors.neutral900 }}',
  navTextLabel: '{{ neutralColors.neutralWhite }}',
};

export const oxxioHeaderDividerHeight = {
  navDividerHeight: '{{ borderWidths.s }}',
};
