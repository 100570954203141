export const borderShadows = {
  shadowHover: `0 0 0 {{ borderWidths.m }} {{ borderColors.outlineHover }}`,
  shadowError: `0 0 0 {{ borderWidths.m }} {{ formColors.formOutlineError }}`,
  shadowSelected: `0 0 0 {{ borderWidths.m }} {{ borderColors.borderSelected }}`,
};

export const boxShadows = {
  xs: '0 3px 5px rgba(26, 23, 27, 0.2)',
  s: '0 5px 10px rgba(26, 23, 27, 0.1)',
  m: '0 10px 15px rgba(26, 23, 27, 0.05)',
  l: '0 10px 30px rgba(26, 23, 27, 0.2)',
};
