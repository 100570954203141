export const brandColors = {
  brandBlue: '#03A2FF',
  brandDarkBlue: '#000649',
};

export const brandGradients = {
  brandGradientStart: '{{ brandColors.brandBlue }}',
  brandGradientEnd: '{{ brandColors.brandBlue }}',
};

export const brandSupport = {
  brandGreen: '#00AB60',
  brandGrey: '#E9E8ED',
};

export const neutralColors = {
  neutralWhite: '#FFFFFF',
  neutral25: '#F2F3F6',
  neutral100: '#DEDFE5',
  neutral200: '#C5C6D4',
  neutral400: '#9D9FB7',
  neutral500: '#7A7E9C',
  neutral700: '#717591',
  neutral900: '#3B416F',
  black: '#000649',
};

// For a hex to percentage opacity conversion, see https://davidwalsh.name/hex-opacity
export const opacityColors = {
  blackOpacity40: `#00000040`,
  darkBlueOpacity70: '{{ brandColors.brandDarkBlue }}70',
};

export const secondaryColors = {
  // Purple
  purple25: '#DDC3FF',
  purple100: '#9D7ED2',

  // blue
  blue25: '#D9F1FF',
  blue100: '#64BBEE',

  // green
  green25: '#DBEEE1',
  green65: '#A5D3B3',
  green100: '#4BA867',
  green200: '#008002',

  // Red
  red25: '#FDE3E5',
  red100: '#EC6D6D',
  red100Opacity30: 'rgba(236, 109, 109, 0.3)',
  red200: '#A13030',

  // Orange
  orange25: '#FFF7E0',
  orange100: '#FDAB31',

  // Yellow
  yellow25: '#FFFAEA',
  yellow65: '#FFDC7A',
  yellow100: '#FDC831',
  yellow120: '#F2B303',

  // Pink
  pink100: '#FBDBE3',
  pink900: '#552748',
};

// General decision tokens

export const backgroundColors = {
  backgroundPrimary: '{{ neutralColors.neutralWhite }}',
  backgroundSecondary: '{{ neutralColors.neutral25 }}',
  backgroundTertiary: '{{ neutralColors.neutral100 }}',
  backgroundBrand: '{{ brandColors.brandBlue }}',
  backgroundScrim: '{{ opacityColors.darkBlueOpacity70 }}',
  backgroundDark: '{{ brandColors.brandDarkBlue }}',
  backgroundPressed: '{{ neutralColors.neutral100 }}',
};

export const backgroundColoredColors = {
  backgroundVarOne: '{{ neutralColors.neutralWhite }}',
  backgroundVarTwo: '{{ neutralColors.neutralWhite }}',
  backgroundVarThree: '{{ neutralColors.neutralWhite }}',
  backgroundVarFour: '{{ neutralColors.neutralWhite }}',
  backgroundVarFive: '{{ neutralColors.neutralWhite }}',
  backgroundVarSix: '{{ neutralColors.neutralWhite }}',
};

export const backgroundSitecoreColors = {
  backgroundCMSVarOne: '{{ brandColors.brandBlue }}',
  backgroundCMSVarTwo: '{{ brandColors.brandDarkBlue }}',
  backgroundCMSVarThree: '{{ secondaryColors.yellow100 }}',
  backgroundCMSVarFour: '{{ brandSupport.brandGreen }}',
  backgroundCMSVarFive: '{{ brandSupport.brandGrey }}',
  backgroundCMSVarSix: '{{ neutralColors.neutralWhite }}',
  backgroundCMSVarSeven: '{{ neutralColors.neutralWhite }}',
  backgroundCMSVarEight: '{{ neutralColors.neutralWhite }}',
};

export const textColors = {
  textPrimary: '{{ neutralColors.black }}',
  textInverted: '{{ neutralColors.neutralWhite }}',
  textBrand: '{{ brandColors.brandBlue }}',
  textOnBackgroundVarOne: '{{ neutralColors.black }}',
  textOnBackgroundVarTwo: '{{ neutralColors.black }}',
  textOnBackgroundVarThree: '{{ neutralColors.black }}',
  textOnBackgroundVarFour: '{{ neutralColors.black }}',
  textOnBackgroundVarFive: '{{ neutralColors.black }}',
  textOnBackgroundVarSix: '{{ neutralColors.black }}',
  textLowEmphasis: '{{ neutralColors.neutral700 }}',
  textHighlightVarOne: '{{ brandColors.brandBlue }}',
  textHighlightVarTwo: '{{ brandColors.brandBlue }}',
  textHighlightVarThree: '{{ brandColors.brandBlue }}',
  textHighlightVarFour: '{{ brandColors.brandBlue }}',
  textHighlightVarFive: '{{ brandColors.brandBlue }}',
  textHighlightVarSix: '{{ brandColors.brandBlue }}',
};

export const iconColors = {
  iconPrimary: '{{ brandColors.brandBlue }}',
  iconSecondary: '{{ neutralColors.neutral500 }}',
  iconTertiary: '{{ neutralColors.black }}',
  iconInverted: '{{ neutralColors.neutralWhite }}',
  iconBrand: '{{ brandColors.brandBlue }}',
  iconGas: '{{ secondaryColors.purple100 }}',
  iconHeat: '{{ neutralColors.black }}',
  iconCooling: '{{ neutralColors.black }}',
  iconWater: '{{ neutralColors.black }}',
  iconTotal: '{{ brandColors.brandBlue }}',
  iconElectricity: '{{ secondaryColors.green100 }}',
  iconSolar: '{{ secondaryColors.yellow100 }}',
  iconOnBackgroundVarOne: '{{ neutralColors.black }}',
  iconOnBackgroundVarTwo: '{{ neutralColors.black }}',
  iconOnBackgroundVarThree: '{{ neutralColors.black }}',
  iconOnBackgroundVarFour: '{{ neutralColors.black }}',
  iconOnBackgroundVarFive: '{{ neutralColors.black }}',
  iconOnBackgroundVarSix: '{{ neutralColors.black }}',
  currentColor: 'currentColor',
};

export const borderColors = {
  borderDividerLowEmphasis: '{{ neutralColors.neutral200 }}',
  borderDividerMediumEmphasis: '{{ neutralColors.neutral400 }}',
  borderDividerHighEmphasis: '{{ neutralColors.neutral500 }}',
  borderFocus: '{{ neutralColors.neutral900 }}',
  borderSelected: '{{ secondaryColors.green100 }}',
  outlineHover: '{{ neutralColors.neutral100 }}',
};

export const linkColors = {
  linkBrand: '{{ textColors.textBrand }}',
  linkPrimary: '{{ textColors.textPrimary }}',
  linkSecondary: '{{ textColors.textLowEmphasis }}',
  linkDisabled: '{{ neutralColors.neutral400 }}',
  linkInverted: '{{ textColors.textInverted }}',
};

export const controlColors = {
  controlsActive: '{{ secondaryColors.green100 }}',
  controlsInactive: '{{ neutralColors.neutral100 }}',
  controlsKnob: '{{ neutralColors.neutralWhite }}',
};

export const feedbackColors = {
  feedbackError: '{{ secondaryColors.red100 }}',
  feedbackSuccess: '{{ secondaryColors.green100 }}',
  feedbackWarning: '{{ secondaryColors.orange100 }}',
  feedbackInfo: '{{ secondaryColors.blue100 }}',
  feedbackBackgroundError: '{{ secondaryColors.red25 }}',
  feedbackBackgroundSuccess: '{{ secondaryColors.green25 }}',
  feedbackBackgroundWarning: '{{ secondaryColors.orange25 }}',
  feedbackBackgroundInfo: '{{ secondaryColors.blue25 }}',
};

export const formColors = {
  formBorderDefault: '{{ neutralColors.neutral200 }}',
  formBorderError: '{{ secondaryColors.red100 }}',
  formBorderHover: '{{ neutralColors.neutral400 }}',
  formErrorMessageBackground: '{{ secondaryColors.red200 }}',
  formOutlineError: '{{ secondaryColors.red100Opacity30 }}',
};

export const graphsColors = {
  graphsTotal: '{{ brandColors.brandBlue }}',
  graphsGasPrimary: '{{ secondaryColors.purple100 }}',
  graphsGasSecondary: '{{ secondaryColors.purple25 }}',
  graphsElectricityPrimary: '{{ secondaryColors.green100 }}',
  graphsElectricitySecondary: '{{ secondaryColors.green65 }}',
  graphsSolarPrimary: '{{ secondaryColors.yellow100 }}',
  graphsSolarSecondary: '{{ secondaryColors.yellow25 }}',
  graphsWarmthPrimary: '{{ neutralColors.black }}',
  graphsWaterPrimary: '{{ neutralColors.black }}',
  graphsFixedCosts: '{{ neutralColors.neutral500 }}',
  graphsEstimatedPrimary: '{{ neutralColors.neutral25 }}',
  graphsEstimatedSecondary: '{{ neutralColors.neutral500 }}',
  graphComparePrimary: '{{ secondaryColors.yellow100 }}',
  graphCompareSecondary: '{{ brandColors.brandBlue }}',
  graphCompareStickerPositive: '{{ secondaryColors.green100 }}',
  graphCompareStickerNeutral: '{{ secondaryColors.blue100 }}',
  graphCompareStickerNegative: '{{ secondaryColors.orange100 }}',
  graphCompareInnerTextColor: '{{ neutralColors.black }}',
};

export const globalThemeColors = {
  ...neutralColors,
  ...opacityColors,
  ...brandColors,
  ...brandGradients,
  ...backgroundColors,
  ...backgroundColoredColors,
  ...backgroundSitecoreColors,
  ...textColors,
  ...iconColors,
  ...borderColors,
  ...linkColors,
  ...controlColors,
  ...feedbackColors,
  ...formColors,
  ...graphsColors,
};
