import tokens from './tokens';

const theme = {
  colors: {
    ...tokens.globalThemeColors,
    ...tokens.button.oxxioButtonColors,
    ...tokens.expandable.oxxioExpandableTokens,
    ...tokens.desktopHeader.oxxioHeaderColors,
    ...tokens.messageBar.oxxioMessagebarColors,
    ...tokens.navLink.oxxioNavlinkOncolorColors,
    ...tokens.progressIndicator.oxxioProgressIndicatorTokensColors,
    ...tokens.ribbon.oxxioRibbonColors,
    ...tokens.badge.oxxioBadgeColors,
  },
  shadows: {
    ...tokens.boxShadows,
    ...tokens.borderShadows,
  },
  outlines: tokens.outlines,
  fonts: {
    body: 'Graphik, sans-serif',
    heading: 'Boing, sans-serif',
  },
  fontSizes: tokens.fontSizes,
  fontWeights: tokens.fontWeights,
  letterSpacings: tokens.letterSpacings,
  lineHeights: tokens.lineHeights,
  space: tokens.space,
  sizes: tokens.sizes,
  borderWidths: {
    ...tokens.borderWidths,
    ...tokens.button.oxxioButtonBorderWidths,
    ...tokens.desktopHeader.oxxioHeaderDividerHeight,
  },
  radii: {
    ...tokens.radii,
    ...tokens.button.oxxioButtonBorderRadii,
  },
  transitions: tokens.transitions,
  typography: {
    ...tokens.bodyTypography,
    ...tokens.headingTypography,
  },
  opacities: tokens.opacities,
  display: {
    ...tokens.button.oxxioButtonChevronDisplay,
  },
  components: {
    // @todo all component tokens should be collected here eventually..
    image: tokens.image.oxxioImageTokens,
  },
};

export { theme };
