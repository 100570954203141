export const oxxioButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: '{{ textColors.textPrimary }}',

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: '{{ secondaryColors.yellow100 }}',
  buttonPrimaryOnLightHoverBackground: '{{ secondaryColors.yellow120 }}',
  buttonPrimaryOnLightFocusBackground: '{{ secondaryColors.yellow100 }}',
  buttonPrimaryOnLightPressedBackground: '{{ secondaryColors.yellow120 }}',

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: '{{ textColors.textPrimary }}',
  buttonPrimaryOnColorHoverText: '{{ textColors.textPrimary }}',
  buttonPrimaryOnColorFocusText: '{{ textColors.textPrimary }}',
  buttonPrimaryOnColorPressedText: '{{ textColors.textPrimary }}',

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: '{{ secondaryColors.yellow100 }}',

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: '{{ iconColors.iconPrimary }}',
  buttonPrimaryOnColorHoverIcon: '{{ iconColors.iconPrimary }}',
  buttonPrimaryOnColorFocusIcon: '{{ iconColors.iconPrimary }}',
  buttonPrimaryOnColorPressedIcon: '{{ iconColors.iconPrimary }}',

  // Secondary
  buttonSecondaryDefaultText: '{{ textColors.textBrand }}',
  buttonSecondaryDefaultBackground: '{{ neutralColors.neutralWhite }}',
  buttonSecondaryDefaultBorderColor: '{{ brandColors.brandBlue }}',
  buttonSecondaryHoverText: '{{ textColors.textPrimary }}',
  buttonSecondaryHoverBackground: '{{ neutralColors.neutralWhite }}',
  buttonSecondaryHoverBorderColor: '{{ neutralColors.black }}',
  buttonSecondaryFocusBackground: '{{ neutralColors.neutralWhite }}',
  buttonSecondaryFocusBorderColor: '{{ neutralColors.black }}',
  buttonSecondaryPressedBackground: '{{ neutralColors.neutralWhite }}',

  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonPrimaryRebrandDefaultText: '{{ textColors.textPrimary }}',
  buttonPrimaryRebrandDefaultBackground: '{{ secondaryColors.yellow100 }}',
  buttonPrimaryRebrandHoverBackground: '{{ secondaryColors.yellow120 }}',
  buttonPrimaryRebrandPressedBackground: '{{ secondaryColors.yellow120 }}',

  // Loading
  buttonLoadingDefaultText: '{{ textColors.textPrimary }}',
  buttonLoadingDefaultBackground: '{{ secondaryColors.yellow100 }}',
};

export const oxxioButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: '{{ borderWidths.m }}',
};

export const oxxioButtonBorderRadii = {
  buttonBorderRadiusDefault: '{{ radii.round }}',
  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonBorderRadiusRebrand: '{{ radii.round }}',
};

export const oxxioButtonChevronDisplay = {
  buttonChevronDisplay: 'block',
};
